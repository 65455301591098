/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { api } from '../../../parameters'
import { catchError } from '../../../actions'
import LoadingPage from '../../../components/LoadingPage'
import { WithUnit, SpanLink } from '../Components'
import { getVaccineOrProductByBatchNumber } from '../ReverseRedirections'
import ProductsForOrderContext from '../../../context/ProductsForOrderContext'

function PreparationsProduced({ token, location, intl, history }) {
  const [state, setState] = useState({
    loading: true,
    items: null,
    batchNumber: null
  })

  const types = {
    injection: 0,
    fine_gouttes: 1,
    grosses_gouttes: 2
  }

  const { getProducts } = useContext(ProductsForOrderContext)
  const unknownValue = intl.formatMessage({ id: 'app.label.N/A' })

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const id = Number(pathName[pathName.length - 2])
    Axios.get(`${api}/api/order_traceability/${id}/preparation`, {
      headers: { Authorization: token }
    })
      .then(res => {
        const mapper = i => {
          const vaccines = []
          if (i.vaccines) {
            Object.entries(i.vaccines).forEach(v => {
              vaccines.push({
                key: v[0],
                value: v[1]
              })
            })
          }
          return {
            ...i,
            date: i.date ? moment(i.date).format('DD/MM/YYYY') : unknownValue,
            hour: i.date ? moment(i.date).format('HH:mm') : unknownValue,
            operator: i.operator ?? unknownValue,
            products: i.products,
            doses: {
              text: i.doses ?? unknownValue,
              unit: 'doses'
            },
            dosesConsumed: {
              text: i.dosesConsumed ?? unknownValue,
              unit: 'doses'
            },
            sequence: i.sequence ?? unknownValue,
            endReal: i.endReal ? moment(i.endReal).format('DD/MM/YYYY [à] HH[h]mm') : unknownValue,
            waterBathTemp: {
              text: i.waterBathTemp ?? unknownValue,
              unit: 'bathTemp'
            },
            vaccines: vaccines.length > 0 ? vaccines.map(v => v.value).join('\n') : unknownValue,
            batchVaccines: vaccines.length > 0 ? vaccines.map(v => v.key) : [unknownValue],
            qr: i.qr ?? unknownValue,
            empty: i.empty,
            comment: i.comment,
            shared: i.shared,
            addition: i.addition?.date
              ? {
                  date: i.addition.date
                    ? moment(i.addition.date).format('DD/MM/YYYY [à] HH[h]mm')
                    : unknownValue,
                  doses: i.addition.doses ?? unknownValue,
                  operator: i.addition.operator ?? unknownValue
                }
              : null,
            deletion: i.deletion?.date
              ? {
                  date: i.deletion.date
                    ? moment(i.deletion.date).format('DD/MM/YYYY [à] HH[h]mm')
                    : unknownValue,
                  doses: i.deletion.doses ?? unknownValue,
                  operator: i.deletion.operator ?? unknownValue
                }
              : null,
            visible: false
          }
        }

        const items = []
        items[types.injection] = []
        items[types.fine_gouttes] = []
        items[types.grosses_gouttes] = []
        if (res.data['hydra:member'].type_0 !== undefined) {
          Object.entries(res.data['hydra:member'].type_0).forEach(i => {
            items[types.injection].push(mapper({ key: i[0], ...i[1] }))
          })
        }
        if (res.data['hydra:member'].type_1 !== undefined) {
          Object.entries(res.data['hydra:member'].type_1).forEach(i => {
            items[types.fine_gouttes].push(mapper({ key: i[0], ...i[1] }))
          })
        }
        if (res.data['hydra:member'].type_2 !== undefined) {
          Object.entries(res.data['hydra:member'].type_2).forEach(i => {
            items[types.grosses_gouttes].push(mapper({ key: i[0], ...i[1] }))
          })
        }
        setState({
          loading: false,
          items,
          batchNumber: res.data.currentNumber
        })
      })
      .catch(err => catchError(err))
  }, [])

  return (
    <div className="row Container stockAvailibilityItem">
      <div className="col s12 Content">
        <div className="row pageHeader pageTitle itemHeader">
          <div
            onClick={() => history.goBack()}
            role="button"
            tabIndex="0"
            onKeyPress={() => history.goBack()}
            className="goBackBtn col s1"
          >
            <i className="material-icons">chevron_left</i>
          </div>
          {state.batchNumber !== null && (
            <h5 className="col s12">
              {intl.formatMessage({ id: 'app.title.order' })} {state.batchNumber} -{' '}
              {intl.formatMessage({ id: 'app.title.preparations_produced' })}
            </h5>
          )}
        </div>
        {!state.loading ? (
          <div className="row">
            {state.items.map((productType, key) => {
              return (
                productType.length > 0 && (
                  <>
                    <div>
                      {intl.formatMessage({
                        id:
                          key === types.injection
                            ? 'app.label.order.preparations_produced.product_bags'
                            : key === types.fine_gouttes
                            ? 'app.label.order.preparations_produced.1'
                            : 'app.label.order.preparations_produced.13'
                      })}
                    </div>
                    <table className="table" style={{ marginBottom: '16px' }}>
                      <thead>
                        <tr>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.operator'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.product'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.3'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.4'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.5'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.6'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.7'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.8'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.9'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.10'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.11'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.12'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.preparation_status'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.coherence'
                            })}
                          </th>
                          <th>
                            {intl.formatMessage({
                              id: 'app.label.order.preparations_produced.shared_preparation'
                            })}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {productType.map((item, pKey) => {
                          return (
                            <>
                              <tr
                                onClick={() => {
                                  if (item.addition != null || item.deletion != null) {
                                    const updatedItems = state.items
                                    updatedItems[key][pKey] = {
                                      ...item,
                                      visible: !item.visible
                                    }
                                    setState({ items: updatedItems })
                                  }
                                }}
                              >
                                <td>{item.operator}</td>
                                <td style={{ whiteSpace: 'pre-wrap' }}>
                                  {getProducts(item.products, unknownValue)}
                                </td>
                                <td>{item.sequence}</td>
                                <td>{item.qr}</td>
                                <td>{item.date}</td>
                                <td>{item.hour}</td>
                                <td style={{ whiteSpace: 'pre-wrap' }}>{item.vaccines}</td>
                                <td>
                                  {item.batchVaccines.map((batchVaccine, index) => {
                                    return (
                                      <>
                                        <SpanLink
                                          value={batchVaccine}
                                          setLoading={loading => setState({ ...state, loading })}
                                          redirect={getVaccineOrProductByBatchNumber}
                                          token
                                          history
                                        />
                                        {index !== item.batchVaccines.length && <div />}
                                      </>
                                    )
                                  })}
                                </td>
                                <td>
                                  <WithUnit {...item.doses} />
                                </td>
                                <td>{item.endReal}</td>
                                <td>
                                  <WithUnit {...item.dosesConsumed} />
                                  {(item.addition != null || item.deletion != null) && (
                                    <i className="material-icons arrow-right">
                                      keyboard_arrow_down
                                    </i>
                                  )}
                                </td>
                                <td>
                                  <WithUnit {...item.waterBathTemp} />
                                </td>
                                <td>
                                  {intl.formatMessage({
                                    id: `app.label.order.preparations_produced.${
                                      item.empty ? 'empty' : 'not_empty'
                                    }`
                                  })}
                                </td>
                                <td>
                                  {item.comment
                                    ? item.comment
                                    : intl.formatMessage({
                                        id: 'app.label.order.preparations_produced.na'
                                      })}
                                </td>
                                <td>
                                  {intl.formatMessage({
                                    id: `app.label.order.preparations_produced.${
                                      item.shared !== null ? (item.shared ? 'yes' : 'no') : 'na'
                                    }`
                                  })}
                                </td>
                              </tr>
                              {item.addition != null && item.visible && (
                                <>
                                  <tr>
                                    <td colSpan={12}>
                                      <div>
                                        <div className="bold">
                                          {intl.formatMessage({
                                            id: 'app.label.order.preparations_produced.added'
                                          })}
                                        </div>
                                        <div>
                                          <span>
                                            {intl.formatMessage({
                                              id: 'app.label.order.preparations_produced.operator'
                                            })}
                                            &nbsp;:&nbsp;{item.addition.operator}&nbsp;&nbsp;
                                          </span>
                                          <span>{item.addition.date}&nbsp;&nbsp;</span>
                                          <span>
                                            {item.addition.doses}{' '}
                                            {intl.formatMessage({
                                              id:
                                                'app.label.order.preparations_produced.number_of_vials_added'
                                            })}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )}
                              {item.deletion != null && item.visible && (
                                <>
                                  <tr>
                                    <td colSpan={12}>
                                      <div>
                                        <div className="bold">
                                          {intl.formatMessage({
                                            id: 'app.label.order.preparations_produced.deleted'
                                          })}
                                        </div>
                                        <div>
                                          <span>
                                            {intl.formatMessage({
                                              id: 'app.label.order.preparations_produced.operator'
                                            })}
                                            &nbsp;:&nbsp;{item.deletion.operator}&nbsp;&nbsp;
                                          </span>
                                          <span>{item.deletion.date}&nbsp;</span>
                                          <span>
                                            {item.deletion.doses}{' '}
                                            {intl.formatMessage({
                                              id:
                                                'app.label.order.preparations_produced.number_of_vials_deleted'
                                            })}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                  </>
                )
              )
            })}
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(PreparationsProduced)))
