import { FormattedMessage } from 'react-intl'
import React from 'react'
import moment from 'moment'
import HeadSingleHistoryStock from './HeadSingleHistoryStock'
import ReceiptConformity from './ReceiptConformity'

const ProductBagReceipt = props => {
  const { history, operator, date, type } = props
  const { action: data } = history
  const { productBags, receptionDate } = data
  return (
    <div className="Container stockHistory">
      <HeadSingleHistoryStock operator={operator} date={date} action={type}>
        {productBags.length > 0 && (
          <div className="row historyCard">
            <div className="col s12 card">
              <div className="row">
                <h5>
                  <FormattedMessage id="app.label.productBagsReceived" />
                </h5>
              </div>
              {productBags.map(productBag => (
                <div className="col s12 row" key={productBag.batchNumber}>
                  <div className="row">
                    <div className="col s6">
                      <p>
                        <span className="label">
                          <FormattedMessage id="app.label.batchesNumber" />
                          &nbsp;:&nbsp;
                        </span>
                        {productBag.batchNumber}
                      </p>
                    </div>
                    <div className="col s6">
                      <p>
                        <span className="label">
                          <FormattedMessage id="app.label.provider" />
                          &nbsp;:&nbsp;
                        </span>
                        {productBag.provider}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s6">
                      <span className="label">
                        <FormattedMessage id="app.label.reception_date" />
                        &nbsp;:&nbsp;
                      </span>
                      {moment(receptionDate).format('DD/MM/YYYY HH:mm')}
                    </div>
                    <div className="col s6">
                      <p>
                        <span className="label">
                          <FormattedMessage id="app.label.expirationDate" />
                          &nbsp;:&nbsp;
                        </span>
                        {moment(productBag.expirationDate).format('DD/MM/YYYY')}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s6">
                      <p>
                        <span className="label">
                          <FormattedMessage id="app.label.volume" />
                          &nbsp;:&nbsp;
                        </span>
                        {productBag.volume}
                        &nbsp;
                        <FormattedMessage id="app.unit.productBagVolume" />
                      </p>
                    </div>
                    <div className="col s6">
                      <p>
                        <span className="label">
                          <FormattedMessage id="app.label.unitiesReceived" />
                          &nbsp;:&nbsp;
                        </span>
                        {productBag.movedUnits}
                      </p>
                    </div>
                  </div>
                  <div className="divider greyDivider" />
                </div>
              ))}
              <div className="row">
                <div className="col s12">
                  <div className="row">
                    <h5>
                      <FormattedMessage id="app.label.orderConformity" />
                    </h5>
                  </div>
                  <div className="row">
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.batchNumberConformity"
                      conformity={data.batchNumberConformity ?? true}
                      reason={data.batchNumberNoConformityReason}
                    />
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.expirationDateConformity"
                      conformity={data.expirationDateConformity ?? true}
                      reason={data.expirationDateNoConformityReason}
                    />
                  </div>
                  <div className="row">
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.nameConformityProductBag"
                      conformity={data.nameConformity ?? true}
                      reason={data.nameNoConformityReason}
                    />
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.quantityConformityVaccine"
                      conformity={data.quantityConformity ?? true}
                      reason={data.quantityNoConformityReason}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </HeadSingleHistoryStock>
    </div>
  )
}

export default ProductBagReceipt
