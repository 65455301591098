import { FormattedMessage } from 'react-intl'
import React, { useContext } from 'react'
import moment from 'moment'
import HeadSingleHistoryStock from './HeadSingleHistoryStock'
import ReceiptConformity from './ReceiptConformity'
import FridgeBlock from './FridgeBlock'
import ValenceContext from '../../../../context/ValencesContext'

const RefrigeratedVaccinesReceipt = props => {
  const { history, operator, date, type } = props
  const { action: data } = history
  const { vaccinesBatches, fridge, receptionDate } = data
  const { getValenceLabelByKey } = useContext(ValenceContext)

  return (
    <div className="Container stockHistory">
      <HeadSingleHistoryStock operator={operator} date={date} action={type}>
        {vaccinesBatches.length > 0 && (
          <div className="row historyCard">
            <div className="col s12 card">
              <FridgeBlock fridge={fridge} />
              <div className="divider greyDivider" />
              <div className="row">
                <div className="col s12">
                  <div className="row">
                    <h5>
                      <FormattedMessage id="app.label.vaccinesReceived" />
                    </h5>
                  </div>
                </div>
              </div>

              {vaccinesBatches.map(vaccine => (
                <div className="row" key={vaccine.batchNumber}>
                  <div className="col s12 row">
                    <div className="col s12">
                      <h6>
                        {vaccine.speciality} {vaccine.article} {vaccine.batchNumber}
                      </h6>
                    </div>
                  </div>
                  <div className="col s12 row">
                    <div className="col s4">
                      <span className="label">
                        <FormattedMessage id="app.label.batchesNumber" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccine.batchNumber}
                    </div>
                    <div className="col s4">
                      <span className="label">
                        <FormattedMessage id="app.label.reception_date" />
                        &nbsp;:&nbsp;
                      </span>
                      {moment(receptionDate).format('DD/MM/YYYY HH:mm')}
                    </div>
                    <div className="col s4">
                      <span className="label">
                        <FormattedMessage id="app.label.expirationDate" />
                        &nbsp;:&nbsp;
                      </span>
                      {moment(vaccine.expirationDate).format('DD/MM/YYYY')}
                    </div>
                  </div>
                  <div className="col s12 row">
                    <div className="col s4">
                      <span className="label">
                        <FormattedMessage id="app.label.speciality" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccine.speciality}
                    </div>
                    <div className="col s4">
                      <span className="label">
                        <FormattedMessage id="app.label.article" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccine.article}
                    </div>
                    <div className="col s4">
                      <span className="label">
                        <FormattedMessage id="app.label.valence" />
                        &nbsp;:&nbsp;
                      </span>
                      {getValenceLabelByKey(vaccine.valence)}
                    </div>
                  </div>
                  <div className="col s12 row">
                    <div className="col s4">
                      <span className="label">
                        <FormattedMessage id="app.label.conservationMethod" />
                        &nbsp;:&nbsp;
                      </span>
                      <FormattedMessage
                        id={`app.label.conservationMethod.${vaccine.conservationMethod}`}
                      />
                    </div>
                    <div className="col s4">
                      <span className="label">
                        <FormattedMessage id="app.label.bulbsReceived" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccine.movedUnits}
                    </div>
                    <div className="col s4">
                      <span className="label">
                        <FormattedMessage id="app.label.dosesNumberReceived" />
                        &nbsp;:&nbsp;
                      </span>
                      {vaccine.movedDoses}
                    </div>
                  </div>
                  <div className="divider greyDivider" />
                </div>
              ))}
              <div className="row">
                <div className="col s12">
                  <div className="row">
                    <h5>
                      <FormattedMessage id="app.label.orderConformity" />
                    </h5>
                  </div>
                  <div className="row">
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.batchNumberConformity"
                      conformity={data.batchNumberConformity ?? true}
                      reason={data.batchNumberNoConformityReason}
                    />
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.expirationDateConformity"
                      conformity={data.expirationDateConformity ?? true}
                      reason={data.expirationDateNoConformityReason}
                    />
                  </div>
                  <div className="row">
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.nameConformityVaccine"
                      conformity={data.nameConformity ?? true}
                      reason={data.nameNoConformityReason}
                    />
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.quantityConformityVaccine"
                      conformity={data.quantityConformity ?? true}
                      reason={data.quantityNoConformityReason}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </HeadSingleHistoryStock>
    </div>
  )
}

export default RefrigeratedVaccinesReceipt
