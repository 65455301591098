import React from 'react'
import { injectIntl } from 'react-intl'
import { exiprationDateDays } from '../../../parameters'
import StatePill from '../../StatePill'
import NoBlockAlertNumberPart from './partials/NoBlockAlertNumberPart'
import NoBlockArticlePart from './partials/NoBlockArticlePart'
import NoBlockEquipmentPart from './partials/NoBlockEquipmentPart'
import NoBlockStockPart from './partials/NoBlockStockPart'
import NoBlockVaccinationPart from './partials/NoBlockVaccinationPart'
import NoBlockDoseNumberPart from './partials/NoBlockDoseNumberPart'

class NoBlockPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = { editParamProducts: props.editParamProducts }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const {
      nonBlockingAlert,
      vaccinationParamId,
      productType,
      paramId,
      action,
      history,
      alertNumber,
      stockId,
      specialityData,
      articleId,
      article
    } = this.props
    const { editParamProducts } = this.state
    if (stockId && !history) {
      const { minimalStockAlert } = specialityData
      this.setState({
        noBlockUnitsStock: minimalStockAlert.value,
        noBlockPartAlertStock: minimalStockAlert.message
      })
    } else if (nonBlockingAlert && nonBlockingAlert.value) {
      this.setState({
        doses: nonBlockingAlert.value,
        dosesMessage: nonBlockingAlert.message
      })
    } else if (articleId && !history) {
      const { nonBlockingExpirationAlert } = article
      this.setState({
        noBlockPartAlertExpirationDate:
          nonBlockingExpirationAlert === null ? '' : nonBlockingExpirationAlert.message
      })
    } else if (
      paramId !== undefined ||
      productType !== undefined ||
      vaccinationParamId !== undefined
    ) {
      if (!history) {
        if (vaccinationParamId === 1) {
          if (nonBlockingAlert.value.includes(0)) {
            this.setState({ vaccin: true })
          }
          if (nonBlockingAlert.value.includes(1)) {
            this.setState({ productBag: true })
          }
          if (nonBlockingAlert.value.includes(2)) {
            this.setState({ diluent: true })
          }
          if (nonBlockingAlert.value.includes(3)) {
            this.setState({ dye: true })
          }
        } else if (vaccinationParamId === 8) {
          this.setState({
            noBlockPartAlertMin: nonBlockingAlert.message.min,
            noBlockPartAlertMax: nonBlockingAlert.message.max
          })
        }

        this.setState({
          noBlockUnitsStock: nonBlockingAlert.value.stock,
          noBlockPartAlert: nonBlockingAlert.message,
          noBlockPartAlertStock: nonBlockingAlert.message.stock,
          noBlockPartAlertExpirationDate: nonBlockingAlert.message.expirationDate,
          noBlockPartAlertMin: nonBlockingAlert.message.min,
          noBlockPartAlertMax: nonBlockingAlert.message.max
        })
      } else {
        const { changes } = action

        if (changes) {
          const nonBlockingAlert2 = changes.nonBlockingAlert

          if (nonBlockingAlert2 && alertNumber === undefined) {
            this.setState({
              noBlockPartAlertBefore: nonBlockingAlert2[0].message,
              noBlockPartAlertMinBefore: nonBlockingAlert2[0].message.min,
              noBlockPartAlertMaxBefore: nonBlockingAlert2[0].message.max,
              noBlockPartAlertAfter: nonBlockingAlert2[1].message,
              noBlockPartAlertMinAfter: nonBlockingAlert2[1].message.min,
              noBlockPartAlertMaxAfter: nonBlockingAlert2[1].message.max
            })
          } else if (nonBlockingAlert2 && alertNumber) {
            this.setState({
              noBlockUnitsStockBefore: nonBlockingAlert2[0].value.stock,
              noBlockExpirationDateBefore: nonBlockingAlert2[0].value.expirationDate,
              noBlockPartAlertStockBefore: nonBlockingAlert2[0].message.stock,
              noBlockPartAlertExpirationDateBefore: nonBlockingAlert2[0].message.expirationDate,
              noBlockUnitsStockAfter: nonBlockingAlert2[1].value.stock,
              noBlockExpirationDateAfter: nonBlockingAlert2[1].value.expirationDate,
              noBlockPartAlertStockAfter: nonBlockingAlert2[1].message.stock,
              noBlockPartAlertExpirationDateAfter: nonBlockingAlert2[1].message.expirationDate
            })
          } else if (action.nonBlockingAlert) {
            this.setState({
              noBlockPartAlertBefore: action.nonBlockingAlert.message,
              noBlockPartAlertAfter: action.nonBlockingAlert.message,
              noBlockPartAlertMinAfter: action.nonBlockingAlert.message.min,
              noBlockPartAlertMaxAfter: action.nonBlockingAlert.message.max
            })
          }
        }
      }
    } else if (editParamProducts === undefined) {
      this.setState({
        noBlockUnitsStock: null,
        noBlockPartAlertStock: null
      })
    }
  }

  handleChange(e) {
    const { setDataToParent } = this.props
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      this.setState({ [name]: checked })
      setDataToParent(name, checked)
    } else {
      this.setState({ [name]: value })
      setDataToParent(name, value)
    }
  }

  render() {
    const {
      congruentPartValueMin,
      congruentPartValueMax,
      blockValueMin,
      blockValueMax,
      paramId,
      intl,
      vaccinationParamId,
      alertNumber,
      history,
      editParamProducts,
      nonBlockingAlert,
      action,
      specialityData,
      stockId,
      articleId,
      article,
      congruentPartNoBlockValueMin,
      blockPartNoBlockValueMax,
      congruentPartNoBlockValueMaxValue,
      congruentPartNoBlockValueMaxPercent
    } = this.props
    const {
      noBlockPartAlert,
      noBlockPartAlertStock,
      noBlockUnitsStock,
      noBlockPartAlertExpirationDate,
      noBlockPartAlertMin,
      noBlockPartAlertMax,
      vaccin,
      productBag,
      diluent,
      dye,
      noBlockPartAlertBefore,
      noBlockPartAlertAfter,
      noBlockPartAlertMinAfter,
      noBlockPartAlertMaxAfter,
      noBlockPartAlertMinBefore,
      noBlockPartAlertMaxBefore,
      noBlockUnitsStockBefore,
      noBlockUnitsStockAfter,
      noBlockExpirationDateBefore,
      noBlockExpirationDateAfter,
      noBlockPartAlertStockBefore,
      noBlockPartAlertStockAfter,
      noBlockPartAlertExpirationDateBefore,
      noBlockPartAlertExpirationDateAfter,
      doses,
      dosesMessage
    } = this.state
    let renderNoBlock = null
    if (stockId) {
      renderNoBlock = (
        <NoBlockStockPart
          history={history}
          specialityData={specialityData}
          stockId={stockId}
          action={action}
          noBlockUnitsStock={noBlockUnitsStock}
          noBlockPartAlertStock={noBlockPartAlertStock}
          handleChange={this.handleChange}
        />
      )
    } else if (doses) {
      renderNoBlock = (
        <NoBlockDoseNumberPart
          doses={doses}
          dosesMessage={dosesMessage}
          handleChange={this.handleChange}
        />
      )
    } else if (articleId) {
      renderNoBlock = (
        <NoBlockArticlePart
          history={history}
          article={article}
          exiprationDateDays={exiprationDateDays}
          noBlockPartAlertExpirationDate={noBlockPartAlertExpirationDate}
          action={action}
          articleId={articleId}
          handleChange={this.handleChange}
        />
      )
    } else if (alertNumber) {
      renderNoBlock = (
        <NoBlockAlertNumberPart
          noBlockExpirationDateAfter={noBlockExpirationDateAfter}
          noBlockExpirationDateBefore={noBlockExpirationDateBefore}
          noBlockPartAlertExpirationDateAfter={noBlockPartAlertExpirationDateAfter}
          noBlockPartAlertStock={noBlockPartAlertStock}
          history={history}
          alertNumber={alertNumber}
          noBlockUnitsStock={noBlockUnitsStock}
          noBlockUnitsStockAfter={noBlockUnitsStockAfter}
          noBlockUnitsStockBefore={noBlockUnitsStockBefore}
          noBlockPartAlertStockBefore={noBlockPartAlertStockBefore}
          noBlockPartAlertStockAfter={noBlockPartAlertStockAfter}
          nonBlockingAlert={nonBlockingAlert}
          noBlockPartAlertExpirationDate={noBlockPartAlertExpirationDate}
          noBlockPartAlertExpirationDateBefore={noBlockPartAlertExpirationDateBefore}
          editParamProducts={editParamProducts}
          exiprationDateDays={exiprationDateDays}
          handleChange={this.handleChange}
          action={action}
        />
      )
    }

    if (paramId !== undefined) {
      renderNoBlock = (
        <NoBlockEquipmentPart
          history={history}
          noBlockPartAlertAfter={noBlockPartAlertAfter}
          noBlockPartAlertBefore={noBlockPartAlertBefore}
          noBlockPartAlertMinAfter={noBlockPartAlertMinAfter}
          noBlockPartAlertMaxAfter={noBlockPartAlertMaxAfter}
          noBlockPartAlertMinBefore={noBlockPartAlertMinBefore}
          noBlockPartAlertMaxBefore={noBlockPartAlertMaxBefore}
          paramId={paramId}
          blockValueMin={blockValueMin}
          congruentPartValueMin={congruentPartValueMin}
          congruentPartValueMax={congruentPartValueMax}
          blockValueMax={blockValueMax}
          noBlockPartAlert={noBlockPartAlert}
          handleChange={this.handleChange}
          noBlockPartAlertMin={noBlockPartAlertMin}
          noBlockPartAlertMax={noBlockPartAlertMax}
        />
      )
    } else if (vaccinationParamId !== undefined && vaccinationParamId !== 10) {
      renderNoBlock = (
        <NoBlockVaccinationPart
          handleChange={this.handleChange}
          history={history}
          vaccinationParamId={vaccinationParamId}
          action={action}
          noBlockPartAlert={noBlockPartAlert}
          noBlockPartAlertMax={noBlockPartAlertMax}
          noBlockPartAlertMin={noBlockPartAlertMin}
          vaccin={vaccin}
          productBag={productBag}
          diluent={diluent}
          dye={dye}
          congruentPartNoBlockValueMaxValue={congruentPartNoBlockValueMaxValue}
          congruentPartNoBlockValueMin={congruentPartNoBlockValueMin}
          congruentPartNoBlockValueMaxPercent={congruentPartNoBlockValueMaxPercent}
          blockPartNoBlockValueMax={blockPartNoBlockValueMax}
        />
      )
    }

    return (
      <div>
        <div className="row">
          <p>
            <StatePill type="noBlockTitleParams" />
            <span className="orangeTitle">
              {intl.formatMessage({ id: 'app.label.alertNoBlockTitleParams' })}
            </span>
          </p>
        </div>
        {renderNoBlock}
      </div>
    )
  }
}

export default injectIntl(NoBlockPart)
