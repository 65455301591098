import { FormattedMessage } from 'react-intl'
import React from 'react'
import moment from 'moment'
import HeadSingleHistoryStock from './HeadSingleHistoryStock'
import ReceiptConformity from './ReceiptConformity'
import FridgeBlock from './FridgeBlock'

const DyesReceipt = props => {
  const { history, operator, date, type } = props
  const { action: data } = history
  const { dyeBatches, fridge, fridgeStocks, receptionDate } = data
  return (
    <div className="Container stockHistory">
      <HeadSingleHistoryStock operator={operator} date={date} action={type}>
        {dyeBatches.length > 0 && (
          <div className="row historyCard">
            <div className="col s12 card">
              <div className="row">
                <div className="col s12">
                  <div className="row" key={fridge.name}>
                    <FridgeBlock fridge={fridge} />
                    <div className="col s12 row">
                      <h5>
                        <FormattedMessage id="app.label.dyesReceived" />
                      </h5>
                    </div>
                    {dyeBatches.map(dye => (
                      <div className="col s12 row" key={dye.batchNumber}>
                        <div className="row">
                          <h6>{dye.batchNumber}</h6>
                        </div>
                        <div className="row">
                          <div className="col s6">
                            <p>
                              <span className="label">
                                <FormattedMessage id="app.label.quantityReceived" />
                                &nbsp;:&nbsp;
                              </span>
                              {fridgeStocks.map(fridgeStock => (
                                <span
                                  key={`${fridgeStock.fridge} ${fridgeStock.dyeBatch} ${fridgeStock.vialsNumber}`}
                                >
                                  {fridgeStock.fridge === fridge.name &&
                                    fridgeStock.dyeBatch === dye.batchNumber && (
                                      <span>{dye.movedUnits}</span>
                                    )}
                                </span>
                              ))}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col s6">
                            <span className="label">
                              <FormattedMessage id="app.label.reception_date" />
                              &nbsp;:&nbsp;
                            </span>
                            {moment(receptionDate).format('DD/MM/YYYY HH:mm')}
                          </div>
                          <div className="col s6">
                            <p>
                              <span className="label">
                                <FormattedMessage id="app.label.expirationDate" />
                                &nbsp;:&nbsp;
                              </span>
                              {moment(dye.expirationDate).format('DD/MM/YYYY')}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col s6">
                            <p>
                              <span className="label">
                                <FormattedMessage id="app.label.color" />
                                &nbsp;:&nbsp;
                              </span>
                              {dye.color}
                            </p>
                          </div>
                          <div className="col s6">
                            <p>
                              <span className="label">
                                <FormattedMessage id="app.label.provider" />
                                &nbsp;:&nbsp;
                              </span>
                              {dye.provider}
                            </p>
                          </div>
                        </div>
                        <div className="divider greyDivider" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <div className="row">
                    <h5>
                      <FormattedMessage id="app.label.orderConformity" />
                    </h5>
                  </div>
                  <div className="row">
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.batchNumberConformity"
                      conformity={data.batchNumberConformity ?? true}
                      reason={data.batchNumberNoConformityReason}
                    />
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.expirationDateConformity"
                      conformity={data.expirationDateConformity ?? true}
                      reason={data.expirationDateNoConformityReason}
                    />
                  </div>
                  <div className="row">
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.nameConformityDye"
                      conformity={data.nameConformity ?? true}
                      reason={data.nameNoConformityReason}
                    />
                    <ReceiptConformity
                      divSize="s6"
                      typeConformity="app.label.quantityConformityDye"
                      conformity={data.quantityConformity ?? true}
                      reason={data.quantityNoConformityReason}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </HeadSingleHistoryStock>
    </div>
  )
}

export default DyesReceipt
