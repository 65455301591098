import React, { createContext, useState, useEffect } from 'react'
import fetchItems from './fetchItems'
import getLabelByKey from './getLabelByKey'

const ProductsForOrderContext = createContext({
  productsForOrder: [],
  getProductForOrderLabelByKey: () => {},
  getProducts: () => {},
  getProductsWithGender: () => {},
  fetchProductsForOrder: () => {}
})

export const ProductsForOrderProvider = ({ children }) => {
  const [productsForOrder, setProductsForOrder] = useState([])

  const fetchProductsForOrder = () => {
    fetchItems('products', setProductsForOrder)
  }

  useEffect(() => {
    fetchProductsForOrder()
  }, [])

  const getProductForOrderLabelByKey = key => getLabelByKey(productsForOrder, key)

  const getProducts = (products, defaultValue) =>
    products && Array.isArray(products)
      ? products.map(p => getProductForOrderLabelByKey(p)).join(', ')
      : defaultValue

  const getProductsWithGender = (productsWithGender, defaultValue) =>
    productsWithGender
      ? Object.values(productsWithGender)
          .map(p => `${getProductForOrderLabelByKey(p.product)} - ${p.gender}`)
          .join(', ')
      : defaultValue

  return (
    <ProductsForOrderContext.Provider
      value={{
        productsForOrder,
        getProductForOrderLabelByKey,
        getProducts,
        getProductsWithGender,
        fetchProductsForOrder
      }}
    >
      {children}
    </ProductsForOrderContext.Provider>
  )
}

export default ProductsForOrderContext
