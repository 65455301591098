import Axios from 'axios'
import moment from 'moment'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import M from 'materialize-css'
import { catchError } from '../../../actions'
import AsyncLoader from '../../../components/AsyncLoader'
import LoadingPage from '../../../components/LoadingPage'
import { api, i18n } from '../../../parameters'
import icons from '../../../assets/icons/icons'
import { ExportFile } from '../../../components/globalAction'
import { RefreshBtn } from '../Components'

class List extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      url: `${api}/api/order_traceabilities`,
      loading: false,
      elementList: [],
      contentStatus: false,
      totalItems: 0,
      currentNumber: null,
      clientName: null,
      startDate: null,
      page: 1
    }
    this.loadMore = this.loadMore.bind(this)
  }

  componentDidMount() {
    const { location } = this.props
    const urlSearch = new URLSearchParams(location.search)
    const currentNumber = urlSearch.get('currentNumber')
    const clientName = urlSearch.get('clientName')
    const startDate = urlSearch.get('startDate')
    this.setState(
      {
        currentNumber,
        clientName,
        startDate: startDate ? new Date(startDate) : null
      },
      () => {
        M.AutoInit()
        M.Datepicker.init(document.getElementById('startDate'), {
          autoClose: true,
          format: 'dd/mm/yyyy',
          selectMonth: true,
          firstDay: 1,
          i18n,
          defaultDate: startDate ? new Date(startDate) : new Date(),
          setDefaultDate: startDate,
          onSelect: dateSelected => {
            const { instanceStartDate } = this.state
            instanceStartDate.gotoDate(new Date(dateSelected))
            this.setState({ startDate: dateSelected })
            this.fetchEntries()
          }
        })
        const instanceStartDate = M.Datepicker.getInstance(document.getElementById('startDate'))
        this.setState({ instanceStartDate })
        document.addEventListener('scroll', this.loadMore)
        if (currentNumber || clientName || startDate) {
          this.fetchEntries()
        }
      }
    )
  }

  loadMore() {
    const { page, totalItems } = this.state
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (totalItems > page * 30) {
        this.setState({ page: page + 1 }, () => this.fetchEntries(true))
      }
    }
  }

  fetchEntries(loadMore) {
    const { token, history, location } = this.props
    const {
      url,
      currentNumber,
      clientName,
      startDate,
      // eslint-disable-next-line no-unused-vars
      page,
      elementList
    } = this.state
    this.setState({ loading: true })
    // const pageQueryParam = `page=${page}`
    const pageQueryParam = ``
    const periodQueryParam = startDate
      ? `period=${moment(startDate).format('YYYY-MM-DD')}..${moment(startDate).format(
          'YYYY-MM-DD'
        )}`
      : ''
    const currentNumberQueryParam = currentNumber ? `&currentNumber=${currentNumber}` : ''
    const clientNameQueryParam = clientName ? `&clientName=${clientName}` : ''
    const newUrlQuery = `${
      periodQueryParam !== '' ? `&startDate=${moment(startDate).format('YYYY-MM-DD')}` : ''
    }${currentNumberQueryParam}${clientNameQueryParam}`
    const poppedUrl = location.pathname.split('?').pop()
    const currentUrlQuery = poppedUrl !== location.pathname ? poppedUrl : ''
    if (newUrlQuery !== currentUrlQuery) {
      history.push({
        pathname: `/traceability/order/list/?${newUrlQuery}`
      })
    }
    Axios.get(
      `${url}?${pageQueryParam}${periodQueryParam}${currentNumberQueryParam}${clientNameQueryParam}`,
      {
        headers: { Authorization: token }
      }
    )
      .then(res => {
        this.setState({
          loading: false,
          elementList: loadMore
            ? elementList.concat(res.data['hydra:member'])
            : res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
          contentStatus: res.data['hydra:totalItems'] > 30 ? false : 'noMoreResult',
          page: 1
        })
      })
      .catch(err => catchError(err))
  }

  render() {
    const { intl, history, role, token } = this.props
    // eslint-disable-next-line no-unused-vars
    const { loading, elementList, contentStatus, currentNumber, clientName, startDate } = this.state
    return (
      <div className="row Container stockHistory">
        <div className="col s12 Content paramsContent traceability">
          <div className="row pageHeader pageTitle">
            <h5 className="col m10">
              {intl.formatMessage({ id: 'app.label.traceability_order' })}
            </h5>
            {startDate !== null ? (
              <>
                <div className="col m1">
                  <RefreshBtn
                    redirect={() => {
                      history.push({
                        pathname: `/traceability/order/list`
                      })
                    }}
                  />
                </div>
                <div className="col m1">
                  <ExportFile
                    data={{ role, token }}
                    url={`/api/order_traceabilities/export/xlsx?startAt=${moment(startDate).format(
                      'YYYY-MM-DD'
                    )}&endAt=${moment(startDate).format('YYYY-MM-DD')}${
                      currentNumber ? `&currentNumber=${currentNumber}` : ''
                    }${clientName ? `&clientName=${clientName}` : ''}`}
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
          <div>
            <div className="filters">
              <div className="input">
                <input
                  placeholder={intl.formatMessage({
                    id: 'app.label.order_number'
                  })}
                  type="text"
                  className="currentNumber"
                  value={currentNumber}
                  onChange={e => {
                    const { value } = e.target
                    this.setState({ currentNumber: value }, () => {
                      this.fetchEntries()
                    })
                  }}
                />
              </div>
              <div className="input">
                <div className="filterDate">
                  <input
                    type="text"
                    className="datepicker"
                    id="startDate"
                    name="startDate"
                    readOnly
                    placeholder={intl.formatMessage({ id: 'app.label.order_hatching_date' })}
                  />
                  <img src={icons.blueCalendar} alt="calendar icon" />
                </div>
              </div>
              <div className="input">
                <input
                  placeholder={intl.formatMessage({
                    id: 'app.label.order_customer_name'
                  })}
                  type="text"
                  className="clientName"
                  value={clientName}
                  onChange={e => {
                    const { value } = e.target
                    this.setState({ clientName: value }, () => {
                      this.fetchEntries()
                    })
                  }}
                />
              </div>
            </div>
          </div>
          {!loading ? (
            <div className="row">
              <table className="table">
                <thead>
                  <tr>
                    <th>{intl.formatMessage({ id: 'app.label.order_date' })}</th>
                    <th>
                      {intl.formatMessage({
                        id: 'app.label.order_number'
                      })}
                    </th>
                    <th>{intl.formatMessage({ id: 'app.label.order_customer' })}</th>
                    <th>{intl.formatMessage({ id: 'app.label.order_breeding' })}</th>
                    <th>{intl.formatMessage({ id: 'app.label.order_chick_number' })}</th>
                  </tr>
                </thead>
                <tbody>
                  {elementList.map(item => {
                    return (
                      <tr
                        key={item.id}
                        onClick={() => {
                          history.push({
                            pathname: `/traceability/order/single/${item.id}`
                          })
                        }}
                        className="pointer"
                      >
                        <td>
                          {item.firstOccurrenceDate
                            ? moment(item.firstOccurrenceDate).format('DD/MM/YYYY')
                            : intl.formatMessage({ id: 'app.label.traceability_unknown' })}
                        </td>
                        <td>
                          {item.currentNumber
                            ? item.currentNumber
                            : intl.formatMessage({ id: 'app.label.traceability_unknown' })}
                        </td>
                        <td>
                          {item.clientName
                            ? item.clientName
                            : intl.formatMessage({ id: 'app.label.traceability_unknown' })}
                        </td>
                        <td>
                          {item.farmer
                            ? item.farmer
                            : intl.formatMessage({ id: 'app.label.traceability_unknown' })}
                        </td>
                        <td>
                          {item.maleChicks !== null && item.femaleChicks !== null
                            ? `M : ${item.maleChicks}/ F : ${item.femaleChicks}`
                            : intl.formatMessage({ id: 'app.label.traceability_unknown' })}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                <tfoot className="center">
                  <tr>
                    <th colSpan="5">
                      <AsyncLoader status={contentStatus} />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          ) : (
            <LoadingPage />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(List)))
