import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import M from 'materialize-css'
import Swal from 'sweetalert2'
import { catchError } from '../../../../../actions'
import volumes from '../../../../../config/bottleVolumes'
import { api } from '../../../../../parameters'
import CenterButton from '../../../../CenterButton'
import distributeVaccinesInOneContainerVaccine from './distributeVaccinesInOneContainerVaccine'
import BottleBlock from './partials/BottleBlock/BottleBlock'
import OrderInfosBlock from './partials/OrderInfosBlock'
import SprayAddBlock from './partials/SprayAddBlock'
import VaccinesBlock from './partials/VaccinesBlock/VaccinesBlock'
// import VaccineFormBlock from './partials/VaccineFormBlock/VaccineFormBlock'

class SprayedVaccinesStep extends React.Component {
  constructor(props) {
    super()
    this.state = {
      result: {},
      resTotal: props.total,
      bottlesDoses: volumes[5],
      showVaccines: false
    }

    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setTotal = this.setTotal.bind(this)
    this.setBottlesDoses = this.setBottlesDoses.bind(this)
    this.enableSubmit = this.enableSubmit.bind(this)
    this.setResult = this.setResult.bind(this)
    this.setShowVaccines = this.setShowVaccines.bind(this)
    this.setBottlesDosesTotal = this.setBottlesDosesTotal.bind(this)
  }

  componentDidUpdate() {
    M.AutoInit()
  }

  getStateFromChild(result) {
    this.setState({ ...result })
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  setTotal(resTotal) {
    if (resTotal <= 0) {
      this.setState({ canSubmit: true })
    }

    this.setState({ resTotal })
  }

  setBottlesDoses(bottlesDoses) {
    this.setState({ bottlesDoses })
  }

  setBottlesDosesTotal(bottlesDosesTotal) {
    this.setState({ bottlesDosesTotal })
  }

  setResult(result) {
    const { fridgeVaccines } = result
    let canSubmit = !!fridgeVaccines

    for (const key in fridgeVaccines) {
      if (Object.prototype.hasOwnProperty.call(fridgeVaccines, key)) {
        const vaccines = fridgeVaccines[key]

        for (const vacc of vaccines) {
          if (vacc.batch === undefined) {
            canSubmit = false
          }
        }
      }
    }

    this.setState({ result, canSubmit })
  }

  setShowVaccines(showVaccines) {
    this.setState({ showVaccines })
  }

  enableSubmit(canSubmit) {
    this.setState({ canSubmit })
  }

  handleSubmit() {
    const { changePart, group, token, vaccineType, order, addSubmit, intl } = this.props
    const { result } = this.state

    this.setState({ canSubmit: false }, () => {
      const preparations = []
      let vaccines = {}

      if (result.fridgeVaccines !== undefined) {
        vaccines = result.fridgeVaccines
        const vaccineOrders = []

        order.vaccines.forEach(vaccine => {
          vaccineOrders.push({ id: vaccine.id, doseType: vaccine.doseType })
        })

        const vaccinesOrdersQuantity = {}
        const vaccinesOrdersList = {}

        vaccineOrders.forEach(vacc => {
          if (vaccinesOrdersList[vacc.id] === undefined) {
            vaccinesOrdersList[vacc.id] = []
            vaccinesOrdersQuantity[vacc.id] = 0
          }

          vaccines[vacc.id].forEach(vaccine => {
            vaccinesOrdersQuantity[vacc.id] += vaccine.quantity

            for (let i = 0; i < vaccine.quantity; i += 1) {
              const vaccineToPush = {
                id: vaccine.batch.id,
                quantity: 1,
                doses: vaccine.batch.vaccinesBatch.vaccineArticle.dosesNumber
              }
              vaccinesOrdersList[vacc.id].push(vaccineToPush)
            }
          })
        })

        let vaccinesToSubmit
        const { bottles } = result

        for (const vacc in vaccinesOrdersList) {
          if (Object.prototype.hasOwnProperty.call(vaccinesOrdersList, vacc)) {
            let total = 0

            for (const batch of vaccinesOrdersList[vacc]) {
              total += batch.doses
            }

            if (total % bottles.doses !== 0) {
              return Swal.fire({
                text: intl.formatMessage({ id: 'app.message.badVaccinesSelectionBottle' }),
                type: 'warning',
                confirmButtonText: intl.formatMessage({ id: 'app.label.ok' }),
                customClass: {
                  icon: 'swalWarningIcon',
                  title: 'swalTitle',
                  content: 'swalContentText',
                  confirmButton: 'blueBtn'
                }
              })
            }
          }
        }

        for (let cpt = 0; cpt < bottles.quantity; cpt++) {
          vaccinesToSubmit = distributeVaccinesInOneContainerVaccine(
            vaccineOrders,
            vaccinesOrdersList,
            bottles.doses
          )

          preparations[cpt] = {
            bottle: bottles.doses,
            vaccines: vaccinesToSubmit,
            dye: result.color,
            diluent: result.diluent
          }
        }
      }

      let groupOrder

      if (group.bigDropsOrder !== null && vaccineType === 2) {
        groupOrder = group.bigDropsOrder
      } else {
        groupOrder = group.thinDropsOrder
      }

      const { id } = groupOrder

      return changePart
        ? Axios.patch(
            `${api}/api/vaccination/preparation/sprayed/${id}`,
            { preparations },
            {
              headers: { Authorization: token }
            }
          )
            .then(() => changePart())
            .catch(err => catchError(err.response))
        : addSubmit(preparations)
    })
  }

  render() {
    const { group, headerText, intl, order, total, isAdding, hatchingDate } = this.props
    const {
      result,
      resTotal,
      bottlesDoses,
      bottlesDosesTotal,
      showVaccines,
      canSubmit
    } = this.state

    return (
      <>
        {isAdding === undefined ? (
          <OrderInfosBlock group={group} headerText={headerText} total={total} />
        ) : (
          ''
        )}
        {resTotal > 0 ? (
          <>
            <BottleBlock
              neededDoses={resTotal}
              group={group}
              setBottlesDoses={this.setBottlesDoses}
              setBottlesDosesTotal={this.setBottlesDosesTotal}
              result={result}
              setResult={this.setResult}
              setShowVaccines={this.setShowVaccines}
            />
            {showVaccines === true && (
              <>
                <VaccinesBlock
                  volume={bottlesDoses}
                  neededDoses={bottlesDosesTotal}
                  order={order}
                  result={result}
                  setResult={this.setResult}
                  hatchingDate={hatchingDate}
                />
                <SprayAddBlock
                  data={result}
                  setStateToParent={this.getStateFromChild}
                  hatchingDate={hatchingDate}
                />
              </>
            )}
          </>
        ) : (
          <div className="row endRealisation">
            <div className="custom-card flex flex-center noPreparation">
              {intl.formatMessage({ id: 'app.message.thePreparationReuseIsSuffisant' })}
            </div>
          </div>
        )}
        {canSubmit && (
          <CenterButton
            text={intl.formatMessage({ id: 'app.button.validate' })}
            onClick={this.handleSubmit}
            disabled={!canSubmit}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(SprayedVaccinesStep))
