import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import QRCode from 'qrcode.react'
import CardHeader from './partials/CardHeader'
import StatePill from '../../../StatePill'

const OrderEndReal = props => {
  const { hist, intl } = props
  const { action: data } = hist.history
  const { preparation } = data
  return (
    <div className="vaccinationHistory">
      <div className="row historyCard">
        <CardHeader date={hist.createdAt} operator={hist.firstName} />
        <div className="col s12 card" key={data.containerNumber}>
          <div className="row line">
            <div className="col s6">
              {Object.entries(data.orders).map(orderNumber => (
                <div className="row">
                  <div className="col s6">
                    <span className="bold">
                      {intl.formatMessage({ id: 'app.label.orderNumber' })} :{' '}
                    </span>{' '}
                    {orderNumber[0]}
                    {orderNumber[1][0].realized === false && (
                      <span className="blue-tag ">Suivant</span>
                    )}
                  </div>
                  <div className="col s6">
                    <span className="bold">
                      {intl.formatMessage({ id: 'app.label.theoricalUsedDoses' })} :{' '}
                    </span>{' '}
                    {Object.entries(orderNumber[1]).map(([key, order]) => {
                      return (
                        order.doses + (orderNumber[1].length === parseInt(key) + 1 ? '' : ' + ')
                      )
                    })}
                  </div>
                </div>
              ))}
            </div>
            <div className="col s6">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.order_end_real.preparationDate' })} :
              </span>{' '}
              {moment(preparation.createdAt).format('DD/MM/YYYY HH:mm')}
            </div>
          </div>
          <div className="row line">
            <div className="col s4">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.order_end_real.presentationDate' })} :
              </span>{' '}
              {moment(preparation.firstScannedAt).format('DD/MM/YYYY HH:mm')}
            </div>
            <div className="col s4">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.order_end_real.scanDate' })} :
              </span>{' '}
              {moment(preparation.secondScannedAt).format('DD/MM/YYYY HH:mm')}
            </div>
            <div className="col s4">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.order_end_real.theoricalDate' })} :
              </span>{' '}
              <StatePill type="Parameter" data={preparation.useState} />
              {moment(preparation.expirationDate).format('DD/MM/YYYY HH:mm')}
            </div>
          </div>
          <div className="row line">
            <div className="col s4">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.order_end_real.empty_preparation' })} :
              </span>{' '}
              {intl.formatMessage({
                id: preparation.empty === true ? 'app.label.yes' : 'app.label.no'
              })}
            </div>
            <div className="col s4">
              <span className="bold">{intl.formatMessage({ id: 'app.label.usedDoses' })} :</span>{' '}
              {preparation.dosesConsumed}
            </div>
            <div className="col s4">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.sharedpreparation' })} :
              </span>{' '}
              {intl.formatMessage({
                id: preparation.shared === true ? 'app.label.yes' : 'app.label.no'
              })}
            </div>
          </div>
          {data.comment && (
            <div className="row line">
              <div className="col s12">
                <span className="bold">{intl.formatMessage({ id: 'app.label.comment' })} :</span>{' '}
                {data.comment}
              </div>
            </div>
          )}
          <div className="row line">
            <div className="col s4">
              <span className="bold">
                {intl.formatMessage({ id: 'app.label.order_end_real.reason_of_alert' })} :
              </span>{' '}
              {preparation.comment !== null
                ? preparation.comment
                : intl.formatMessage({
                    id: preparation.empty === true ? 'app.label.no' : 'app.label.na'
                  })}
            </div>
          </div>
          <div className="divider greyDivider" />
          <div className="row line">
            <section className="col s12 center">
              <div id="qr-code">{preparation.qr}</div>
              <QRCode value={preparation.qr} size={130} renderAs="svg" />
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(OrderEndReal)
