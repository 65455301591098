import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import M from 'materialize-css'
import icons from '../../../../../../../assets/icons/icons'
import volumes from '../../../../../../../config/bottleVolumes'

class BottleBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      quantity: 0,
      volume: 0,
      disabled: false
    }

    this.handleVolumeChange = this.handleVolumeChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setVolumeQuantity = this.setVolumeQuantity.bind(this)
    this.reload = this.reload.bind(this)
  }

  componentDidMount() {
    this.setVolumeQuantity(volumes[5])
    M.AutoInit()
  }

  componentDidUpdate() {
    M.AutoInit()
  }

  setVolumeQuantity(volume) {
    const { neededDoses } = this.props

    const quantity = Math.ceil(neededDoses / volume)
    this.setState({ quantity, volume })
  }

  handleVolumeChange(e) {
    let { value } = e.target
    value = parseInt(value)

    this.setVolumeQuantity(value)
  }

  handleSubmit() {
    const { setBottlesDoses, setResult, result, setShowVaccines, setBottlesDosesTotal } = this.props
    const { volume, quantity } = this.state

    setBottlesDoses(volume)
    result.bottles = { doses: volume, quantity }
    setResult(result)
    this.setState({ disabled: true })
    setShowVaccines(true)
    setBottlesDosesTotal(volume * quantity)
  }

  reload() {
    const { setBottlesDoses, setResult, setShowVaccines } = this.props

    setBottlesDoses(0)
    setResult({})
    this.setState({ disabled: false })
    setShowVaccines(false)
  }

  render() {
    const { intl } = this.props
    const { quantity, volume, disabled } = this.state

    return (
      <div className="row form custom-card">
        <div className="card-content">
          <div className="row card-block">
            <div className="col s6">
              <div className="flex">
                <img className="card-logo marginRight15" src={icons.bottle} alt="logo bottle" />
                <span className="bold">{intl.formatMessage({ id: 'app.label.bottle' })}</span>
              </div>
            </div>

            <div className="col offset-l5 l1">
              {disabled === true && (
                <button type="button" className="btn redoBtn" onClick={this.reload}>
                  <i className="material-icons">replay</i>
                </button>
              )}
            </div>
          </div>
          <div className="row card-block">
            <div className="col s6 input">
              <label htmlFor="color" className="labelCustom">
                {intl.formatMessage({ id: 'app.label.volume' })}
              </label>
              <select
                className="select-wrapper"
                name="volume"
                type="number"
                onChange={this.handleVolumeChange}
                value={volume}
                disabled={disabled}
              >
                {volumes.map(vol => (
                  <option key={vol} value={vol}>
                    {intl.formatMessage({ id: `app.bottleVolume.${vol}` })}
                  </option>
                ))}
              </select>
            </div>
            <div className="col s3 input">
              <label htmlFor="quantity" className="labelCustom">
                {intl.formatMessage({ id: 'app.label.quantity' })}
              </label>
              <input name="quantity" type="number" value={quantity} disabled />
            </div>
            <div className="col s3">
              {disabled !== true && (
                <button type="button" className="btn blueBtn" onClick={this.handleSubmit}>
                  {intl.formatMessage({ id: 'app.button.select' })}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(BottleBlock))
