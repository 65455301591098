import React from 'react'
import { injectIntl } from 'react-intl'

const CongruentVaccinationHistoryPart = props => {
  const {
    intl,
    vaccinationParamId,
    congruentPartValueMaxAfter,
    congruentPartValueMaxBefore,
    congruentPartValueMinAfter,
    congruentPartValueMinBefore
  } = props

  switch (vaccinationParamId) {
    case 3:
    case 4:
    case 11:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s3">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.temperatureBetween' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {congruentPartValueMinBefore}
                  {`${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} 
                      ${intl.formatMessage({ id: 'app.label.and' })} 
                      ${congruentPartValueMaxBefore}
                      ${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} `}
                </span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <span className="row">
              <div className="input col s3">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.temperatureBetween' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {congruentPartValueMinAfter}
                  {`${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} 
                      ${intl.formatMessage({ id: 'app.label.and' })} 
                      ${congruentPartValueMaxAfter}
                      ${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} `}
                </span>
              </div>
            </span>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 6:
    case 7:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s3">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.temperatureBetween' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {congruentPartValueMinBefore}
                  {`${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} 
                        ${intl.formatMessage({ id: 'app.label.and' })} 
                        ${congruentPartValueMaxBefore}
                        ${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} `}
                </span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <span className="row">
              <div className="input col s3">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.temperatureBetween' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {congruentPartValueMinAfter}
                  {`${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} 
                        ${intl.formatMessage({ id: 'app.label.and' })} 
                        ${congruentPartValueMaxAfter}
                        ${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} `}
                </span>
              </div>
            </span>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    case 8:
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input col s3">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.temperatureBetween' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {congruentPartValueMinBefore}
                  {`${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} 
                      ${intl.formatMessage({ id: 'app.label.and' })} 
                      ${congruentPartValueMaxBefore}
                      ${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} `}
                </span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <span className="row">
              <div className="input col s3">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.temperatureBetween' })}
                  &nbsp;:&nbsp;
                </label>
                <span>
                  {congruentPartValueMinAfter}
                  {`${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} 
                      ${intl.formatMessage({ id: 'app.label.and' })} 
                      ${congruentPartValueMaxAfter}
                      ${intl.formatMessage({ id: 'app.unit.fridgeTemp' })} `}
                </span>
              </div>
            </span>
          </div>
          <div className="divider greyDivider" />
        </div>
      )
    default:
      return null
  }
}

export default injectIntl(CongruentVaccinationHistoryPart)
