import Axios from 'axios'
import React from 'react'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { catchError } from '../../../actions'
import { closePage, validatedAction } from '../../../components/globalAction'
import LoadingPage from '../../../components/LoadingPage'
import InjectedVaccinesStep from '../../../components/Vaccination/Preparation/Process/VaccinesSteps/InjectedVaccinesStep'
import ThinDropsVaccinesStep from '../../../components/Vaccination/Preparation/Process/VaccinesSteps/ThinDropsVaccinesStep'
import BigDropsVaccinesStep from '../../../components/Vaccination/Preparation/Process/VaccinesSteps/BigDropsVaccinesStep'
import OrderBlockInfo from '../../../components/Vaccination/Realisation/AddPreparation/OrderBlockInfo'
import QuantityBlock from '../../../components/Vaccination/Realisation/AddPreparation/QuantityBlock'
import history from '../../../history'
import { api } from '../../../parameters'

class AddPreparation extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      dosesQuantity: '',
      pBagVolume: 2000,
      part: 0
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.changePart = this.changePart.bind(this)
    this.getContent = this.getContent.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    const { id, vaccineType } = match.params

    Axios.get(`${api}/api/planning_order_groups/${id}`, { headers: { Authorization: token } })
      .then(res => {
        const group = res.data
        let order = group.injectionOrder

        if (vaccineType === '1') {
          order = group.thinDropsOrder
        } else if (vaccineType === '2') {
          order = group.bigDropsOrder
        }

        this.setState({ group, order, loading: false })
      })
      .catch(err => catchError(err.response))
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { pBagDosesTotal, pBagVolume, part } = this.state

    if (part > 0) {
      if (prevState.pBagDosesTotal !== pBagDosesTotal) {
        this.calculatePBagDoses(pBagVolume)
      }
    }
  }

  getContent() {
    const { match } = this.props
    const { group, order, resTotal } = this.state
    const { vaccineType } = match.params
    const hatchingDate = moment(group.hatchingDate).format('DD-MM-YYYY')

    switch (vaccineType) {
      case '1':
        return (
          <ThinDropsVaccinesStep
            addSubmit={this.handleSubmit}
            group={group}
            id={group.id}
            order={order}
            totalFixed={group.totalChicks}
            total={resTotal}
            hatchingDate={hatchingDate}
            isAdding
          />
        )
      case '2':
        return (
          <BigDropsVaccinesStep
            addSubmit={this.handleSubmit}
            group={group}
            id={group.id}
            order={order}
            totalFixed={group.totalChicks}
            total={resTotal}
            hatchingDate={hatchingDate}
            isAdding
          />
        )
      default:
        return (
          <InjectedVaccinesStep
            addSubmit={this.handleSubmit}
            group={group}
            id={group.id}
            order={order}
            totalFixed={group.totalChicks}
            total={resTotal}
            hatchingDate={hatchingDate}
            isAdding
          />
        )
    }
  }

  handleChange(e) {
    const { value, name } = e.target

    if (name === 'dosesQuantity') {
      this.setState({ resTotal: parseInt(value) })
    }

    this.setState({ [name]: value })
  }

  changePart() {
    const { part, pBagVolume } = this.state
    const { match } = this.props
    const { vaccineType } = match.params

    if (vaccineType === '0') {
      this.calculatePBagDoses(pBagVolume)
    }

    this.setState({ part: part + 1 })
  }

  calculatePBagDoses(volume) {
    const { resTotal } = this.state

    const pBagDosesTotal = Math.ceil(resTotal / volume) * volume

    this.setState({ pBagDosesTotal })
  }

  handleSubmit(preparations) {
    const { token } = this.props
    const { order, resTotal } = this.state

    Axios.patch(
      `${api}/api/vaccination/realisation/add_preparation/${order.id}`,
      { preparations, total: resTotal },
      {
        headers: { Authorization: token }
      }
    )
      .then(() => validatedAction(''))
      .catch(err => catchError(err.response))
  }

  render() {
    const { intl, match } = this.props
    const { dosesQuantity, group, loading, part, order } = this.state
    const { vaccineType } = match.params

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div className="Container stockAvailibilityItem row">
        <div className="col s12">
          <div className="row pageHeader pageTitle">
            <div className="flex col m12">
              <h5 className="col m12">{intl.formatMessage({ id: 'app.label.addPreparation' })}</h5>
            </div>
            <div className="col">
              <div
                role="button"
                tabIndex="0"
                onKeyPress={() => history.goBack()}
                onClick={() => closePage(intl)}
                className="goBackBtn"
              >
                <i className="material-icons">close</i>
              </div>
            </div>
          </div>
          <OrderBlockInfo order={order} group={group} vaccineType={vaccineType} />
          <QuantityBlock handleChange={this.handleChange} part={part} />
          {part >= 1 && this.getContent()}
          <div className="row" style={part >= 1 ? { display: 'none' } : {}}>
            <div className="col s12 flex flex-center">
              <button
                type="button"
                className="btn blueBtn"
                onClick={this.changePart}
                disabled={dosesQuantity === ''}
              >
                {intl.formatMessage({ id: 'app.button.validate' })}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(AddPreparation)))
