import React from 'react'
import { injectIntl } from 'react-intl'

const BlockVaccinationPart = props => {
  const {
    intl,
    vaccinationParamId,
    history,
    blockAlert,
    blockAlertAfter,
    blockAlertBefore,
    blockPartNoBlockValueMax,
    blockingAlert,
    handleChange,
    action,
    vaccinBlock,
    productBagBlock,
    diluentBlock,
    dyeBlock,
    exiprationDateDays,
    congruentPartBlockValueMin,
    congruentPartBlockValueMax
  } = props

  switch (vaccinationParamId) {
    case 0:
      if (!history) {
        return (
          <div className="row">
            <div className="col s12 input">
              <div className="row">
                <div className="col s12">
                  <label htmlFor="blockAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.mixingVaccinesForbidden' })}
                    &nbsp;
                  </label>
                  <textarea
                    className="materialize-textarea"
                    name="blockAlert"
                    value={blockAlert}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      }
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlertExpirationDateBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;
                </label>
                <span>
                  {action.changes.blockingAlert[0].message || action.blockingAlert.message}
                </span>
              </div>
            </div>
          </div>
          <div className="col s6">
            <div className="row input">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row input">
              <div className="col s12">
                <label htmlFor="noBlockPartAlertExpirationDateBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;
                </label>
                <span>{action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )

    case 1:
      if (!history) {
        return (
          <div className="row">
            <div className="col s12 input">
              <div className="row">
                <div className="col s12">
                  <div className="row">
                    <div className="col s12">
                      <label htmlFor="blockAlert" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.useDiffProductOrVaccine' })}
                        &nbsp;
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <label className="col s3">
                        <input
                          type="checkbox"
                          className="filled-in checkbox"
                          name="vaccinBlock"
                          checked={vaccinBlock}
                          onClick={handleChange}
                        />
                        <span>{intl.formatMessage({ id: 'app.label.vaccin' })}</span>
                      </label>
                      <label className="col s3">
                        <input
                          type="checkbox"
                          className="filled-in checkbox"
                          name="productBagBlock"
                          checked={productBagBlock}
                          onClick={handleChange}
                        />
                        <span>{intl.formatMessage({ id: 'app.label.productBag' })}</span>
                      </label>
                      <label className="col s3">
                        <input
                          type="checkbox"
                          className="filled-in checkbox"
                          name="diluentBlock"
                          checked={diluentBlock}
                          onClick={handleChange}
                        />
                        <span>{intl.formatMessage({ id: 'app.label.diluent' })}</span>
                      </label>
                      <label className="col s3">
                        <input
                          type="checkbox"
                          className="filled-in checkbox"
                          name="dyeBlock"
                          checked={dyeBlock}
                          onClick={handleChange}
                        />
                        <span>{intl.formatMessage({ id: 'app.label.dye' })}</span>
                      </label>
                    </div>
                  </div>
                  <textarea
                    className="materialize-textarea"
                    name="blockAlert"
                    value={blockAlert}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      }
      return (
        <div className="row">
          <div className="col s12">
            <div className="row input">
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.before' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.useDiffProductOrVaccine' })}
                      &nbsp;
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="vaccin"
                        checked={action.changes.blockingAlert[0].value.includes(0) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.vaccin' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="productBag"
                        checked={action.changes.blockingAlert[0].value.includes(1) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.productBag' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        checked={action.changes.blockingAlert[0].value.includes(2) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.diluent' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        checked={action.changes.blockingAlert[0].value.includes(3) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.dye' })}</span>
                    </label>
                  </div>
                </div>
                <div className="row input">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlertStockAfter" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;
                    </label>
                    <span>
                      {action.changes.blockingAlert[0].message || action.blockingAlert.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.after' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.useDiffProductOrVaccine' })}
                      &nbsp;
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="vaccin"
                        checked={action.blockingAlert.value.includes(0) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.vaccin' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        name="productBag"
                        checked={action.blockingAlert.value.includes(1) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.productBag' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        checked={action.blockingAlert.value.includes(2) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.diluent' })}</span>
                    </label>
                    <label className="col s3">
                      <input
                        type="checkbox"
                        className="filled-in checkbox"
                        checked={action.blockingAlert.value.includes(3) || false}
                      />
                      <span>{intl.formatMessage({ id: 'app.label.dye' })}</span>
                    </label>
                  </div>
                </div>
                <div className="row input">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlertStockAfter" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;
                    </label>
                    <span>{action.blockingAlert.message}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )

    case 2:
      if (!history) {
        return (
          <div className="row">
            <div className="col s12">
              <div className="row input">
                <div className="col s12">
                  <div className="row">
                    <div className="input col s6">
                      <label htmlFor="blockExpirationDate" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.expirationDate' })}
                      </label>
                      <select
                        className="select-wrapper"
                        name="blockExpirationDate"
                        defaultValue={blockingAlert.value || '0'}
                        onChange={handleChange}
                      >
                        <option value={blockingAlert.value || '0'} disabled>
                          {blockingAlert.value || intl.formatMessage({ id: 'app.label.selectDay' })}
                        </option>

                        {exiprationDateDays.map(day => (
                          <option key={day.name} value={day.name}>
                            {day.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <label htmlFor="blockAlert" className="labelParams">
                        {intl.formatMessage({ id: 'app.label.alert' })}
                        &nbsp;
                      </label>
                    </div>
                  </div>
                  <textarea
                    className="materialize-textarea"
                    name="blockAlert"
                    value={blockAlert}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      }
      return (
        <div className="row">
          <div className="col s12">
            <div className="row input">
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.before' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.expirationDate' })}
                      &nbsp;
                    </label>
                    <span>
                      {action.changes.blockingAlert[0].value || action.blockingAlert.value}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;
                    </label>
                    <span>
                      {action.changes.blockingAlert[0].message || action.blockingAlert.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.after' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.expirationDate' })}
                      &nbsp;
                    </label>
                    <span>{action.blockingAlert.value}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="noBlockPartAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;
                    </label>
                    <span>{action.blockingAlert.message}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )

    case 3:
    case 4:
    case 11:
      if (!history) {
        return (
          <div className="row">
            <div className="col s12 input">
              <div className="row">
                <div className="col s3">
                  <label htmlFor="blockAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.ifTempHigher' })}
                    &nbsp;
                  </label>
                  <input
                    name="blockPartNoBlockValueMax"
                    type="number"
                    value={blockPartNoBlockValueMax}
                    onChange={handleChange}
                  />
                </div>
                <div className="col s12">
                  <label htmlFor="blockAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                    &nbsp;
                  </label>
                  <textarea
                    className="materialize-textarea"
                    name="blockAlert"
                    value={blockAlert}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      }
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;
                </label>
                <span>{blockAlertBefore || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertAfter" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;
                </label>
                <span>{blockAlertAfter || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )

    case 5:
      if (!history) {
        return (
          <div className="row">
            <div className="col s12 input">
              <div className="row">
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="blockAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.ifVolumeOfWater' })}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="blockAlert" className="labelParams">
                      {intl.formatMessage({ id: 'app.label.alert' })}
                      &nbsp;
                    </label>
                    <textarea
                      className="materialize-textarea"
                      name="blockAlert"
                      value={blockAlert}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      }
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;
                </label>
                <span>{blockAlertBefore || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertAfter" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;
                </label>
                <span>{blockAlertAfter || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )

    case 6:
    case 7:
      if (!history) {
        return (
          <div className="row">
            <div className="col s12 input">
              <div className="row">
                <div className="input col s6">
                  <label htmlFor="blockValueMax" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.ifNbVaccineAndNbChickLowerIncluded' })}
                    &nbsp;
                  </label>
                  <input
                    className="col s3"
                    type="number"
                    value={congruentPartBlockValueMin}
                    disabled
                  />
                  <span className="inputPercent labelCustom col s1">
                    {intl.formatMessage({ id: 'app.unit.percent' })}
                  </span>
                </div>
                <div className="input col s6">
                  <label htmlFor="blockValueMin" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.ifNbVaccineAndNbChickHigherIncluded' })}
                    &nbsp;
                  </label>
                  <input
                    className="col s3"
                    type="number"
                    value={congruentPartBlockValueMax}
                    disabled
                  />
                  <span className="inputPercent labelCustom col s1">
                    {intl.formatMessage({ id: 'app.unit.percent' })}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <label htmlFor="blockAlert" className="labelParams">
                    {intl.formatMessage({ id: 'app.label.alert' })}
                    &nbsp;
                  </label>
                  <textarea
                    className="materialize-textarea"
                    name="blockAlert"
                    value={blockAlert}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="divider greyDivider" />
          </div>
        )
      }
      return (
        <div className="row">
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.before' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifNbVaccineAndNbChickHigher' })}
                  &nbsp;
                </label>
                <span>
                  {action.changes.blockingAlert[0].value.min || action.blockingAlert.value.min}
                </span>
              </div>
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifNbVaccineAndNbChickLower' })}
                  &nbsp;
                </label>
                <span>
                  {action.changes.blockingAlert[0].value.max || action.blockingAlert.value.max}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;
                </label>
                <span>{blockAlertBefore || action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="col s6 input">
            <div className="row">
              <div className="col s12">
                <label htmlFor="congruentPartTempBetweenMin" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.after' })}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifNbVaccineAndNbChickHigher' })}
                  &nbsp;
                </label>
                <span>{action.blockingAlert.value.min}</span>
              </div>
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.ifNbVaccineAndNbChickLower' })}
                  &nbsp;
                </label>
                <span>{action.blockingAlert.value.max}</span>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="blockAlertBefore" className="labelParams">
                  {intl.formatMessage({ id: 'app.label.alert' })}
                  &nbsp;
                </label>
                <span>{action.blockingAlert.message}</span>
              </div>
            </div>
          </div>
          <div className="divider greyDivider" />
        </div>
      )

    default:
      return null
  }
}

export default injectIntl(BlockVaccinationPart)
