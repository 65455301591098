import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import icons from '../../../../../../assets/icons/icons'
import { api } from '../../../../../../parameters'
import LoadingPage from '../../../../../LoadingPage'
import { catchError } from '../../../../../../actions'

class SprayAddBlock extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      diluentsList: [],
      useDiluent: true,
      useDye: true,
      noDiluent: false,
      dyeList: []
    }
    this.handleDiluentCheck = this.handleDiluentCheck.bind(this)
    this.handleDyeCheck = this.handleDyeCheck.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { data, setStateToParent, token, hatchingDate } = this.props
    const { useDiluent } = this.state
    const endPoints = [
      `${api}/api/fridge_diluents?diluentBatch.expirationDate[after]=${hatchingDate}`,
      `${api}/api/fridge_dyes?dyeBatch.expirationDate[after]=${hatchingDate}`
    ]
    if (useDiluent === true) {
      Promise.all(
        endPoints.map(endPoint => Axios.get(endPoint, { headers: { Authorization: token } }))
      )
        .then(([{ data: diluents }, { data: dyes }]) => {
          const mappedDyeList = dyes['hydra:member'].map(i => {
            return {
              id: i.id,
              label: i.dyeBatch?.color,
              batchNumber: i.dyeBatch?.batchNumber,
              fridge: i.fridge?.name
            }
          })
          if (diluents['hydra:member'][0] !== undefined) {
            data.diluent = diluents['hydra:member'][0].id
            const color = mappedDyeList[0] !== undefined ? mappedDyeList[0].id : null
            data.color = color
            this.setState({
              loading: false,
              diluentsList: diluents['hydra:member'],
              dyeList: mappedDyeList,
              useDye: mappedDyeList.length > 0,
              useDiluent: diluents['hydra:member'].length > 0
            })
            setStateToParent(data)
          } else {
            data.diluent = ''
            setStateToParent(data)

            this.setState({
              loading: false,
              noDiluent: true,
              useDiluent: false,
              dyeList: mappedDyeList,
              useDye: mappedDyeList.length > 0
            })
          }
        })
        .catch(err => catchError(err))
    }
    setStateToParent(data)
  }

  handleDiluentCheck(e) {
    const { data, setStateToParent, intl } = this.props
    const { diluentsList, noDiluent } = this.state

    if (noDiluent === true) {
      return Swal.fire({
        type: 'info',
        showCancelButton: false,
        text: intl.formatMessage({ id: 'app.error.noDiluent' }),
        customClass: {
          title: 'swalTitle',
          content: 'swalContentText',
          confirmButton: 'blueBtn'
        }
      })
    }

    const { checked } = e.target

    if (checked === false) {
      data.diluent = ''
      setStateToParent(data)
    } else {
      data.diluent = diluentsList[0].id
      setStateToParent(data)
    }
    this.setState({ useDiluent: checked }, () => M.AutoInit())
  }

  handleDyeCheck(e) {
    const { data, setStateToParent } = this.props
    const { dyeList } = this.state
    const { checked } = e.target
    if (checked === false) {
      data.color = ''
      setStateToParent(data)
    } else {
      data.color = dyeList.length > 0 ? dyeList[0].id : ''
      setStateToParent(data)
    }
    this.setState({ useDye: checked }, () => M.AutoInit())
  }

  handleChange(e) {
    const { data, setStateToParent } = this.props
    const { name, value } = e.target
    const parseValue = parseInt(value)

    this.setState({ [name]: parseValue })
    data[name] = parseValue
    setStateToParent(data)
  }

  render() {
    const { disabled, intl } = this.props
    const { diluentsList, loading, useDiluent, useDye, dyeList } = this.state

    if (loading) {
      return <LoadingPage />
    }

    return (
      <div className="row form custom-card">
        <div className="card-content">
          <div className="row card-block">
            <div className="col s6">
              <div className="flex">
                <img
                  className="card-logo marginRight15"
                  src={icons.dye}
                  alt="logo injectable vaccine"
                />
                <span className="bold">{intl.formatMessage({ id: 'app.label.dye' })}</span>
              </div>
            </div>
            <div className="col s6 ">
              <p> {intl.formatMessage({ id: `app.label.addDye` })}</p>
              <div className="switch">
                <label>
                  {intl.formatMessage({ id: `app.label.no` })}
                  <input
                    checked={useDye}
                    disabled={dyeList.length === 0}
                    type="checkbox"
                    onChange={this.handleDyeCheck}
                  />
                  <span className="lever" />
                  {intl.formatMessage({ id: `app.label.yes` })}
                </label>
              </div>
            </div>
          </div>
          {useDye === true && (
            <>
              <div className="divider greyDivider" />
              <div className="row card-block">
                <div className="col s3 input">
                  <label htmlFor="color" className="labelCustom">
                    {intl.formatMessage({ id: 'app.label.color' })}
                  </label>
                  <select
                    className="select-wrapper"
                    defaultValue="blue"
                    name="color"
                    type="number"
                    disabled={disabled}
                    onChange={this.handleChange}
                  >
                    {dyeList.map(col => (
                      <option key={col.id} value={col.id}>
                        {col.fridge} - {col.batchNumber} -{' '}
                        {intl.formatMessage({ id: `app.color.${col.label}` })}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}
          <div className="row card-block">
            <div className="col s6">
              <div className="flex">
                <img className="card-logo marginRight15" src={icons.diluent} alt="logo diluent" />
                <span className="bold">{intl.formatMessage({ id: 'app.label.diluent' })}</span>
              </div>
            </div>
            <div className="col s6 ">
              <p> {intl.formatMessage({ id: `app.label.addDiluent` })}</p>
              <div className="switch">
                <label>
                  {intl.formatMessage({ id: `app.label.no` })}
                  <input
                    checked={useDiluent}
                    type="checkbox"
                    disabled={diluentsList.length === 0}
                    onChange={this.handleDiluentCheck}
                  />
                  <span className="lever" />
                  {intl.formatMessage({ id: `app.label.yes` })}
                </label>
              </div>
            </div>
          </div>
          {useDiluent === true && (
            <>
              <div className="divider greyDivider" />
              <div className="row card-block">
                <div className="col s3 input">
                  <label htmlFor="color" className="labelCustom">
                    {intl.formatMessage({ id: 'app.label.diluent' })}
                  </label>
                  <select
                    className="select-wrapper"
                    name="diluent"
                    type="number"
                    onChange={this.handleChange}
                    disabled={disabled}
                    defaultValue={diluentsList[0].id}
                  >
                    {diluentsList.map(dil => (
                      <option key={dil.id} value={dil.id}>
                        {`${dil.diluentBatch.batchNumber} - ${dil.fridge.name}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(SprayAddBlock))
