import Axios from 'axios'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { catchError } from '../../../../../actions'
import icons from '../../../../../assets/icons/icons'
import { api } from '../../../../../parameters'
import CenterButton from '../../../../CenterButton'
import distributeVaccinesInOneContainerVaccine from './distributeVaccinesInOneContainerVaccine'
import DyeBlock from './partials/DyeBlock'
import OrderInfosBlock from './partials/OrderInfosBlock'
import PBagBlock from './partials/PBagBlock/PBagBlock'
import VaccinesBlock from './partials/VaccinesBlock/VaccinesBlock'

class InjectedVaccinesStep extends React.Component {
  constructor(props) {
    super()
    this.state = {
      result: {},
      resTotal: props.total,
      pBagVolume: 2000,
      showVaccines: false
    }

    this.getStateFromChild = this.getStateFromChild.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
    this.setTotal = this.setTotal.bind(this)
    this.setPBagVolume = this.setPBagVolume.bind(this)
    this.setShowVaccines = this.setShowVaccines.bind(this)
    this.setTotalNeededDoses = this.setTotalNeededDoses.bind(this)
    this.enableSubmit = this.enableSubmit.bind(this)
    this.setResult = this.setResult.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setResTotal = this.setResTotal.bind(this)
  }

  getStateFromChild(result) {
    this.setState({ ...result })
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  setPBagVolume(pBagVolume) {
    this.setState({ pBagVolume })
  }

  setTotal(resTotal) {
    this.setState({ resTotal })
  }

  setShowVaccines(showVaccines) {
    this.setState({ showVaccines })
  }

  setTotalNeededDoses(totalNeededDoses) {
    this.setState({ totalNeededDoses })
  }

  setResTotal(resTotal) {
    if (resTotal <= 0) {
      this.setState({ canSubmit: true })
    }

    this.setState({ resTotal })
  }

  setResult(result) {
    const { containerVaccines, fridgeVaccines, productBags } = result
    let canSubmit = true

    if (productBags && Object.keys(productBags[productBags.length - 1]).length === 0) {
      canSubmit = false
    }

    for (const key in containerVaccines) {
      if (Object.prototype.hasOwnProperty.call(containerVaccines, key)) {
        const vaccines = containerVaccines[key]

        for (const vacc of vaccines) {
          if (vacc.batch === undefined) {
            canSubmit = false
          }
        }
      }
    }

    for (const key in fridgeVaccines) {
      if (Object.prototype.hasOwnProperty.call(fridgeVaccines, key)) {
        const vaccines = fridgeVaccines[key]

        for (const vacc of vaccines) {
          if (vacc.batch === undefined) {
            canSubmit = false
          }
        }
      }
    }

    this.setState({ result, canSubmit })
  }

  enableSubmit(canSubmit) {
    this.setState({ canSubmit })
  }

  handleSubmit() {
    const { changePart, group, token, addSubmit, intl } = this.props
    const { result } = this.state

    this.setState({ canSubmit: false }, () => {
      const preparations = []
      let vaccines = {}

      if (result.productBags !== undefined && result.productBags.length > 0) {
        vaccines = { ...result.containerVaccines, ...result.fridgeVaccines }
        const vaccineOrders = []
        group.injectionOrder.vaccines.forEach(vaccine => {
          vaccineOrders.push({ id: vaccine.id, doseType: vaccine.doseType })
        })

        const vaccinesOrdersQuantity = {}
        const vaccinesOrdersList = {}

        vaccineOrders.forEach(vacc => {
          if (vaccinesOrdersList[vacc.id] === undefined) {
            vaccinesOrdersList[vacc.id] = []
            vaccinesOrdersQuantity[vacc.id] = 0
          }

          vaccines[vacc.id].forEach(vaccine => {
            vaccinesOrdersQuantity[vacc.id] += vaccine.quantity

            for (let i = 0; i < vaccine.quantity; i += 1) {
              const vaccineToPush = {
                id: vaccine.batch.id,
                quantity: 1,
                doses: vaccine.batch.vaccinesBatch.vaccineArticle.dosesNumber
              }
              vaccinesOrdersList[vacc.id].push(vaccineToPush)
            }
          })
        })

        for (const vacc in vaccinesOrdersList) {
          if (Object.prototype.hasOwnProperty.call(vaccinesOrdersList, vacc)) {
            let total = 0

            for (const batch of vaccinesOrdersList[vacc]) {
              total += batch.doses
            }

            if (total % result.productBags[0].batch.doses !== 0) {
              return Swal.fire({
                text: intl.formatMessage({ id: 'app.message.badVaccinesSelectionPBag' }),
                type: 'warning',
                confirmButtonText: intl.formatMessage({ id: 'app.label.ok' }),
                customClass: {
                  icon: 'swalWarningIcon',
                  title: 'swalTitle',
                  content: 'swalContentText',
                  confirmButton: 'blueBtn'
                }
              })
            }
          }
        }

        let vaccinesToSubmit

        for (let i = 0; i < result.productBags.length; ++i) {
          const productBag = result.productBags[i]

          for (let cpt = 0; cpt < productBag.quantity; cpt++) {
            vaccinesToSubmit = distributeVaccinesInOneContainerVaccine(
              vaccineOrders,
              vaccinesOrdersList,
              productBag.batch.doses
            )

            preparations.push({
              productBag: productBag.batch.id,
              vaccines: vaccinesToSubmit,
              dye: result.color
            })
          }
        }
      }

      return changePart
        ? Axios.patch(
            `${api}/api/vaccination/preparation/injected/${group.injectionOrder.id}`,
            { preparations },
            {
              headers: { Authorization: token }
            }
          )
            .then(() => {
              changePart()
            })
            .catch(err => catchError(err.response))
        : addSubmit(preparations)
    })
  }

  render() {
    const { intl, group, total, isAdding, hatchingDate } = this.props
    const { result, resTotal, pBagVolume, showVaccines, totalNeededDoses, canSubmit } = this.state

    return (
      <>
        {isAdding === undefined ? (
          <OrderInfosBlock group={group} headerText="injectableVaccine" total={total} />
        ) : (
          ''
        )}
        {resTotal > 0 ? (
          <>
            <PBagBlock
              neededDoses={resTotal}
              volume={pBagVolume}
              setVolume={this.setPBagVolume}
              setShowVaccines={this.setShowVaccines}
              setTotalNeededDoses={this.setTotalNeededDoses}
              enableSubmit={this.enableSubmit}
              result={result}
              setResult={this.setResult}
              hatchingDate={hatchingDate}
            />
            {showVaccines === true && (
              <>
                <VaccinesBlock
                  volume={pBagVolume}
                  neededDoses={totalNeededDoses}
                  order={group.injectionOrder}
                  result={result}
                  setResult={this.setResult}
                  hatchingDate={hatchingDate}
                />
                <DyeBlock
                  data={result}
                  order={group.injectionOrder}
                  setStateToParent={this.getStateFromChild}
                  vaccineIcon={icons.injectedVaccine}
                  hatchingDate={hatchingDate}
                />
              </>
            )}
          </>
        ) : (
          <div className="row endRealisation">
            <div className="custom-card flex flex-center noPreparation">
              {intl.formatMessage({ id: 'app.message.thePreparationReuseIsSuffisant' })}
            </div>
          </div>
        )}
        {canSubmit && (
          <CenterButton
            text={intl.formatMessage({ id: 'app.button.validate' })}
            onClick={this.handleSubmit}
            disabled={!canSubmit}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

export default injectIntl(connect(mapStateToProps)(InjectedVaccinesStep))
