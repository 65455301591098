/* eslint-disable prefer-destructuring */
import Axios from 'axios'
import M from 'materialize-css'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { catchError } from '../../../actions'
import { validatedAction } from '../../../components/globalAction'
import LoadingPage from '../../../components/LoadingPage'
import BlockPart from '../../../components/Parameters/BlockPart/BlockPart'
import CongruentPart from '../../../components/Parameters/CongruentPart/CongruentPart'
import NoBlockPart from '../../../components/Parameters/NoBlockPart/NoBlockPart'
import Notification from '../../../components/Parameters/Notification'
import { api } from '../../../parameters'

class VaccinationParamItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getDataFromChild = this.getDataFromChild.bind(this)
  }

  componentDidMount() {
    const { match, token } = this.props
    this.setState({ loading: true })
    Axios.get(`${api}/api/vaccination_parameters/${match.params.id}`, {
      headers: { Authorization: token }
    })
      .then(res => {
        this.setState({ parameters: res.data, loading: false, channels: res.data.channels }, () => {
          if (res.data.nonBlockingAlert.value) {
            if (res.data.nonBlockingAlert.value.isArray) {
              if (res.data.nonBlockingAlert.value.includes(0)) this.setState({ vaccin: true })
              if (res.data.nonBlockingAlert.value.includes(1)) this.setState({ productBag: true })
              if (res.data.nonBlockingAlert.value.includes(2)) this.setState({ diluent: true })
              if (res.data.nonBlockingAlert.value.includes(3)) this.setState({ dye: true })
            }
          }
          if (res.data.blockingAlert.value) {
            if (res.data.blockingAlert.value.isArray) {
              if (res.data.blockingAlert.value.includes(0)) this.setState({ vaccinBlock: true })
              if (res.data.blockingAlert.value.includes(1)) this.setState({ productBagBlock: true })
              if (res.data.blockingAlert.value.includes(2)) this.setState({ diluentBlock: true })
              if (res.data.blockingAlert.value.includes(3)) this.setState({ dyeBlock: true })
            }
          }
        })
      })
      .catch(err => catchError(err.response))

    M.AutoInit()
  }

  getDataFromChild(name, value) {
    this.setState({ [name]: value })
  }

  handleSubmit() {
    const { match, token } = this.props
    const {
      blockAlert,
      hatchRespAlerts,
      vetAlerts,
      hatchingRespAlerts,
      operatorAlerts,
      channels,
      parameters,
      noBlockPartAlert,
      vaccin,
      productBag,
      diluent,
      dye,
      vaccinBlock,
      productBagBlock,
      diluentBlock,
      dyeBlock,
      noBlockPartAlertMin,
      noBlockPartAlertMax,
      congruentPartNoBlockValueMaxValue,
      congruentPartNoBlockValueMaxPercent,
      blockExpirationDate,
      congruentPartNoBlockValueMin,
      blockPartNoBlockValueMax,
      congruentPartBlockValueMin,
      congruentPartBlockValueMax,
      dosesMessage,
      doses
    } = this.state
    const { conformityAlert, nonBlockingAlert, blockingAlert, paramId } = parameters
    const { id } = match.params

    let formData = {}

    if (hatchRespAlerts) channels.push(hatchRespAlerts)
    if (vetAlerts) channels.push(vetAlerts)
    if (hatchingRespAlerts) channels.push(hatchingRespAlerts)
    if (operatorAlerts) channels.push(operatorAlerts)

    const checkboxNoBlock = []
    const checkboxBlock = []

    switch (paramId) {
      case 0:
        formData = {
          conformityAlert: {
            type: conformityAlert.type,
            message: conformityAlert.message,
            value: conformityAlert.value
          },
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: noBlockPartAlert || nonBlockingAlert.message,
            value: nonBlockingAlert.value
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockAlert || blockingAlert.message,
            value: blockingAlert.value
          },
          channels
        }
        break
      case 1:
        if (vaccin) checkboxNoBlock.push(0)
        if (productBag) checkboxNoBlock.push(1)
        if (diluent) checkboxNoBlock.push(2)
        if (dye) checkboxNoBlock.push(3)

        if (vaccinBlock) checkboxBlock.push(0)
        if (productBagBlock) checkboxBlock.push(1)
        if (diluentBlock) checkboxBlock.push(2)
        if (dyeBlock) checkboxBlock.push(3)

        formData = {
          conformityAlert: {},
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: noBlockPartAlert || nonBlockingAlert.message,
            value: checkboxNoBlock || nonBlockingAlert.value
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockAlert || blockingAlert.message,
            value: checkboxBlock || blockingAlert.value
          },
          channels
        }
        break
      case 2:
        formData = {
          conformityAlert: {
            type: conformityAlert.type,
            message: conformityAlert.message,
            value: conformityAlert.value
          },
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: noBlockPartAlert || nonBlockingAlert.message,
            value: ''
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockAlert || blockingAlert.message,
            value: parseInt(blockExpirationDate) || blockingAlert.value
          },
          channels
        }
        break
      case 3:
      case 4:
      case 11:
        formData = {
          conformityAlert: {
            type: conformityAlert.type,
            message: conformityAlert.message,
            value: conformityAlert.value
          },
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: noBlockPartAlert || nonBlockingAlert.message,
            value: {
              min: congruentPartNoBlockValueMin || nonBlockingAlert.value.min,
              max: blockPartNoBlockValueMax || nonBlockingAlert.value.max
            }
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockAlert || blockingAlert.message,
            value: checkboxBlock || blockingAlert.value
          },
          channels
        }
        break
      case 5:
        formData = {
          conformityAlert: {
            type: conformityAlert.type,
            message: conformityAlert.message,
            value: conformityAlert.value
          },
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: noBlockPartAlert || nonBlockingAlert.message,
            value: nonBlockingAlert.value
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockAlert || blockingAlert.message,
            value: blockingAlert.value
          },
          channels
        }
        break
      case 6:
      case 7:
        formData = {
          conformityAlert: {
            type: conformityAlert.type,
            message: conformityAlert.message,
            value: conformityAlert.value
          },
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: noBlockPartAlert || nonBlockingAlert.message,
            value: nonBlockingAlert.value
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockAlert || blockingAlert.message,
            value: {
              max: congruentPartBlockValueMax || blockingAlert.value.max,
              min: congruentPartBlockValueMin || blockingAlert.value.min
            }
          },
          channels
        }
        break
      case 8:
        formData = {
          conformityAlert: {
            type: conformityAlert.type,
            message: conformityAlert.message,
            value: conformityAlert.value
          },
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: {
              min: noBlockPartAlertMin || nonBlockingAlert.message.min,
              max: noBlockPartAlertMax || nonBlockingAlert.message.max
            },
            value: {
              min: congruentPartNoBlockValueMin || nonBlockingAlert.value.min,
              max: {
                value: congruentPartNoBlockValueMaxValue || nonBlockingAlert.value.max.value,
                percent: congruentPartNoBlockValueMaxPercent || nonBlockingAlert.value.max.percent
              }
            }
          },
          blockingAlert: {
            type: blockingAlert.type,
            message: blockingAlert.message,
            value: blockingAlert.value
          },
          channels
        }
        break
      case 10:
        formData = {
          nonBlockingAlert: {
            type: nonBlockingAlert.type,
            message: dosesMessage || nonBlockingAlert.message,
            value: doses
              ? doses
                  .map(dose => {
                    const updatedDose = dose
                    delete updatedDose.notSync
                    return updatedDose
                  })
                  .filter(
                    dose =>
                      dose.doseNumber && dose.min && dose.min.limit && dose.max && dose.max.limit
                  )
              : nonBlockingAlert.value
          },
          channels
        }
        break
      default:
        break
    }

    Axios.patch(`${api}/api/vaccination_parameters/${id}`, formData, {
      headers: { Authorization: token }
    })
      .then(() => {
        validatedAction('')
      })
      .catch(err => catchError(err.response))
  }

  render() {
    const { intl } = this.props
    const {
      loading,
      congruentPartNoBlockValueMin,
      blockPartNoBlockValueMax,
      congruentPartBlockValueMin,
      congruentPartBlockValueMax,
      congruentPartNoBlockValueMaxValue,
      congruentPartNoBlockValueMaxPercent,
      parameters
    } = this.state

    if (loading) {
      return <LoadingPage />
    }

    const { nonBlockingAlert, blockingAlert, paramId } = parameters

    let dataTable1 = null
    let col2Table1 = null
    let col3Table1 = null

    let dataTable2 = null
    let col2Table2 = null
    let col3Table2 = null

    if (paramId === 5) {
      const { value } = blockingAlert

      dataTable1 = Object.values(value)[0]
      col2Table1 = Object.values(dataTable1.BIND)
      col3Table1 = Object.values(dataTable1.COCC)

      dataTable2 = Object.values(value)[1]
      col2Table2 = Object.values(dataTable2.BIND)
      col3Table2 = Object.values(dataTable2.COCC)
    }

    if (loading) {
      return <LoadingPage />
    }

    let goBackBtn = null
    switch (paramId) {
      case 0:
        goBackBtn = '#incompatibility'
        break
      case 6:
      case 7:
        goBackBtn = '#validation'
        break
      case 8:
        goBackBtn = '#dailyRapport'
        break
      case 10:
        goBackBtn = '#endOfRealization'
        break
      default:
        goBackBtn = '#realisation'
    }

    return (
      <div className="row Container params">
        <div className="col s12 content marginTop30">
          <div className="pageTitle pageHeader row col s12">
            <div className="row col s12">
              <div className="flex">
                <h5>
                  <span>{intl.formatMessage({ id: `app.title.vaccinationParam.${paramId}` })}</span>
                </h5>
              </div>
            </div>
            <Link
              className="goBackBtn close right"
              to={`/parameters/parameter-vaccination${goBackBtn}`}
            >
              <i className="material-icons">close</i>
            </Link>
          </div>

          <div className="row col s12">
            <div className="row col s12 card">
              <div className="col s12 form">
                <form>
                  {((paramId > 2 && paramId < 5) ||
                    (paramId > 5 && paramId < 9) ||
                    paramId === 11) && (
                    <CongruentPart
                      nonBlockingAlert={nonBlockingAlert}
                      blockingAlert={blockingAlert}
                      vaccinationParamId={paramId}
                      setDataToParent={this.getDataFromChild}
                    />
                  )}
                  {(paramId < 5 || paramId > 7) && (
                    <div>
                      <NoBlockPart
                        vaccinationParamId={paramId}
                        nonBlockingAlert={nonBlockingAlert}
                        congruentPartNoBlockValueMin={congruentPartNoBlockValueMin}
                        blockPartNoBlockValueMax={blockPartNoBlockValueMax}
                        congruentPartNoBlockValueMaxValue={congruentPartNoBlockValueMaxValue}
                        congruentPartNoBlockValueMaxPercent={congruentPartNoBlockValueMaxPercent}
                        setDataToParent={this.getDataFromChild}
                      />
                    </div>
                  )}
                  {![8, 10].includes(paramId) && (
                    <BlockPart
                      vaccinationParamId={paramId}
                      nonBlockingAlert={nonBlockingAlert}
                      blockingAlert={blockingAlert}
                      congruentPartBlockValueMin={congruentPartBlockValueMin}
                      congruentPartBlockValueMax={congruentPartBlockValueMax}
                      setDataToParent={this.getDataFromChild}
                    />
                  )}
                  {paramId === 5 && (
                    <div className="row">
                      <div className="col s6">
                        <table className="table">
                          <thead>
                            <th>{intl.formatMessage({ id: 'app.label.dosesNumber' })}</th>
                            <th>
                              {intl.formatMessage({ id: 'app.label.waterVolumeVaccineBind' })}
                            </th>
                            <th>
                              {intl.formatMessage({ id: 'app.label.waterVolumeVaccineCocc' })}
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <th>{intl.formatMessage({ id: 'app.label.1000Doses' })}</th>
                              <td>
                                {`${col2Table1[0]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table1[0]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: 'app.label.2000Doses' })}</th>
                              <td>
                                {`${col2Table1[1]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table1[1]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: 'app.label.2500Doses' })}</th>
                              <td>
                                {`${col2Table1[2]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table1[2]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: 'app.label.5000Doses' })}</th>
                              <td>
                                {`${col2Table1[3]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table1[3]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: 'app.label.10000Doses' })}</th>
                              <td>
                                {`${col2Table1[4]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table1[4]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                {intl.formatMessage({ id: 'app.label.volumeReceivedByChick' })}
                              </th>
                              <td>
                                {`${col2Table1[6]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table1[6]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr className="trDarkBlue">
                              <th>{intl.formatMessage({ id: 'app.label.totalVolumePerBox' })}</th>
                              <td>
                                {`${col2Table1[5]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table1[5]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col s6">
                        <table className="table">
                          <thead>
                            <th>{intl.formatMessage({ id: 'app.label.dosesNumber' })}</th>
                            <th>
                              {intl.formatMessage({ id: 'app.label.waterVolumeVaccineBind' })}
                            </th>
                            <th>
                              {intl.formatMessage({ id: 'app.label.waterVolumeVaccineCocc' })}
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <th>{intl.formatMessage({ id: 'app.label.1000Doses' })}</th>
                              <td>
                                {`${col2Table2[0]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table2[0]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: 'app.label.2000Doses' })}</th>
                              <td>
                                {`${col2Table2[1]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table2[1]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: 'app.label.2500Doses' })}</th>
                              <td>
                                {`${col2Table2[2]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table2[2]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: 'app.label.5000Doses' })}</th>
                              <td>
                                {`${col2Table2[3]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table2[3]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr>
                              <th>{intl.formatMessage({ id: 'app.label.10000Doses' })}</th>
                              <td>
                                {`${col2Table2[4]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table2[4]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                {intl.formatMessage({ id: 'app.label.volumeReceivedByChick' })}
                              </th>
                              <td>
                                {`${col2Table2[6]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table2[6]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                            <tr className="trDarkBlue">
                              <th>{intl.formatMessage({ id: 'app.label.totalVolumePerBox' })}</th>
                              <td>
                                {`${col2Table2[5]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                              <td>
                                {`${col3Table2[5]} ${intl.formatMessage({
                                  id: 'app.unit.needleVolume'
                                })}`}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <Notification setDataToParent={this.getDataFromChild} parameters={parameters} />
                  <div className="row">
                    <div className="col offset-s5 s7 marginTop30">
                      <button type="button" className="btn blueBtn" onClick={this.handleSubmit}>
                        {intl.formatMessage({ id: 'app.label.save' })}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(VaccinationParamItem)
